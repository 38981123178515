<template>
  <div class="input-container">
    <div class="input-container-label" :style="{ color: colorlabel }">
      {{ label }}
    </div>
    <div :style="'height:' + height" class="input-container-form__input" :class="{ 'border-errors': hasError }">
      <img v-if="icon" :src="icon" alt="" class="input-container-icon" />
      <input
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :autocomplete="disableAutocomplete ? 'one-time-code' : ''"
        class="input-container-customized__input"
        @input="$emit('input', $event.target.value)"
      />
    </div>
    <span v-for="(err, i) in setErrors" :key="i" class="input-container-error__message">
      <i class="mdi mdi-alert-circle mdi-12px" style="margin-right: 5px"></i>{{ err }}
    </span>
    <span v-if="error" class="input-container-error__message">
      <i class="mdi mdi-alert-circle" style="margin-right: 5px; font-size: 16px;"></i>{{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'InputField',
  props: {
    label: {
      type: String,
      default: '',
    },
    colorlabel: {
      type: String,
      default: '#494a4b',
    },
    icon: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '40px',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: '',
    },
    disableAutocomplete: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    setErrors: [],
  }),
  watch: {
    errors(val) {
      this.setErrors = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  padding: 10px 15px;
  flex-flow: column;
  font: Avenir-Regular;
  &-icon {
    height: 25px;
    align-self: center;
    padding-right: 5px;
  }
  &-label {
    text-align: left;
    font-family: Circular-Std;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: -0.08px;
    color: #494a4b;
  }
  &-form__input {
    border-radius: 3.5px;
    border: solid 1px #c7c7c7;
    background-color: #ffffff;
    display: flex;
    width: 100%;
    padding-left: 20px;
    &.border-errors {
      border-color: #ee6161;
    }
  }
  &-customized__input {
    background-color: transparent;
    color: #737373;
    border: none;
  }
  &-error__message {
    color: red;
    font-family: Avenir-Regular;
    padding: 2px;
    font-size: 12px;
    text-align: start;
  }
  input {
    width: inherit;
    font-size: 14px;
    color: #3e3e3e;
    text-align: left;
    font-family: Avenir-Regular;
    &::placeholder {
      color: #828282;
    }
    &:focus {
      outline: none;
    }
  }
}
</style>
