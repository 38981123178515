<template>
  <button class="jumbo-button" :style="`background-color:${color}`" :disabled="disabled">{{ value }}</button>
</template>

<script>
export default {
  name: 'InputField',
  props: {
    height: {
      type: String,
      default: '48px',
    },
    color: {
      type: String,
      default: '#428ee6',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.jumbo-button {
  padding: 20px;
  border: none;
  outline: none;
  font-family: Circular-Std-Bold;
  font-size: 24px;
  color: #ffffff;
  padding: 10px 40px;
  cursor: pointer;
  width: fit-content;
  align-self: center;
  border-radius: 5px;
}
.jumbo-button:hover {
  opacity: 0.9;
}
</style>
