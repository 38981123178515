<template>
  <div>
    <div class="login3pv-menu">
      <img class="login3pv-menu__img" src="/images/logo_golstats_horizontal_white.svg" />
    </div>
    <div class="login3pv-main">
      <div class="login3pv-main__title">
        GolAnalytics
      </div>
      <div class="login3pv-main__subtitle">
        Mi Rendimiento
      </div>
      <div class="login3pv-main__form">
        <Input label="Email*" placeholder="Usuario" colorlabel="#FFFFFF" v-model="form.user" />
        <Input
          label="Contraseña*"
          placeholder="Contraseña"
          type="password"
          colorlabel="#FFFFFF"
          v-model="form.password"
          @keyup.native="preSubmit"
        />
        <div class="m-20">
          <JumboButton @click.native="login" class="success-button-register" value="Iniciar Sesión" />
        </div>
      </div>
    </div>
    <div class="login3pv-footer-container">
      <div class="login3pv-footer">
        <div class="login3pv-footer__row1">
          <img class="login3pv-menu__img-footer" src="/images/escudo.png" />
        </div>
        <div class="login3pv-footer__row2">
          © Copyright – GolStats S.A.P.I. de C.V.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex';
import Input from '@/components/Form/Input';
import JumboButton from '@/components/Form/JumboButton';
export default {
  name: 'Login3pv',
  components: { Input, JumboButton },
  data() {
    return {
      form: {
        user: '',
        password: '',
      },
    };
  },
  computed: {},
  mounted() {
    window.localStorage.setItem('is3pv', false);
  },
  beforeDestroy() {
    document.body.style.backgroundImage = 'unset';
    document.body.style.backgroundColor = 'white';
  },
  methods: {
    ...mapActions('loginGeneral', ['doLogin3pv']),
    ...mapMutations(['setIsSectionLoading']),
    preSubmit(event) {
      if (event.keyCode === 13) {
        this.login();
      }
    },
    async login() {
      try {
        this.setIsSectionLoading(true);
        this.form.username = this.form.user;
        const result = await this.doLogin3pv(this.form);
        if (result) {
          await this.$router.replace('main3pv');
        }
      } catch (error) {
        this.setIsSectionLoading(false);
        throw new Error(error);
      } finally {
        this.setIsSectionLoading(false);
      }
    },
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
  padding: 0 !important;
  min-height: 100%;
  width: 100%;
  background-image: url('/images/background-desktop@3x11.jpg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
#main-container {
  background-color: unset;
}
#app {
  background-color: unset;
  background-image: unset;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style lang="scss" scope>
.login3pv-footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  @media screen and (max-width: 300px) {
    position: relative;
    margin-top: 85%;
  }
  @media screen and (max-height: 600px) {
    position: relative;
    margin-top: 2rem;
  }
}
.login3pv-footer {
  position: absolute;
  height: 120px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  &__row1 {
    width: 100%;
    height: 50px;
    &__img-footer {
      width: 50px;
      @media screen and (max-width: 300px) {
        width: 40px;
      }
    }
  }
  &__row2 {
    width: 100%;
    height: 70px;
    font-family: 'Avenir-Regular';
    font-size: 15px;
    color: white;
    line-height: 80px;
    @media screen and (max-width: 300px) {
      font-size: 13px;
    }
  }
}
.login3pv-menu {
  z-index: 15;
  min-height: 54px;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 6px 6px 35px;
  position: relative;
  height: 60px;
  color: white;
  @media screen and (max-width: 500px) {
    justify-content: center;
    padding: 0px 0px 0px 0px;
  }
  &__img {
    width: 140px;
    @media screen and (max-width: 500px) {
      width: 193px;
      margin-top: 56px;
    }
  }
}
.login3pv-main {
  width: 300px;
  height: 80%;
  margin-top: 60px;
  display: inline-block;
  text-align: center;
  &__title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-family: 'Avenir-Pro-Bold';
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  &__subtitle {
    width: 100%;
    height: 33px;
    line-height: 40px;
    font-size: 24px;
    font-family: 'Avenir-Regular';
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  &__form {
    margin-top: 10%;
    @media screen and (max-width: 340px) {
      margin-left: -2%;
    }
    @media screen and (max-width: 340px) {
      margin-left: -4%;
    }
  }
}
</style>
